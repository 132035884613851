import store from '@store/index'

function AuthGuard(to, from, next) {
  let user = store.getters['auth/user']

  if (user) {
    if (user.role === 'Admin') {
      next({ name: 'admin.agencies.index' })
    } else if (user.role === 'Agency') {
      next({ name: 'agency.businesses.index' })
    } else {
      next({ name: 'business.dashboard' })
    }
  } else {
    next()
  }
}

export default AuthGuard
