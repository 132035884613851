import lazyLoading from './lazyLoading'

export default [
  { name: 'home', path: '/', component: lazyLoading('Pages/Home'), },
  { name: 'redemption', path: '/:unique_id/redemption', component: lazyLoading('Pages/Redemption'), },
  { name: 'order', path: '/order', component: lazyLoading('Auth/Order'), },
  { name: 'landing', path: '/pages/:id/:business', component: lazyLoading('Pages/Landing'), },
  { name: 'result', path: '/pages/:id/:business/result', component: lazyLoading('Pages/Result'), },
  { name: 'thanks', path: '/t/:token', component: lazyLoading('Pages/Thanks'), },
  { name: 'thanksGeneric', path: '/t/v/:token', component: lazyLoading('Pages/ThanksEmailVerified'), },
  { name: 'loops', path: '/l/:token', component: lazyLoading('Pages/Landing'), },
  { name: 'business.order', path: '/business/order', component: lazyLoading('Auth/OrderBusiness'), },
  { name: 'unsubscribe-email', path: '/unsubscribe/email/:token/:id?', component: lazyLoading('Auth/UnsubscribeEmail'), },
  { name: 'product', path: '/p/:slug', component: lazyLoading('Auth/Product'), },  
  { name: 'goto-authorize', path: '/goto/authorize', component: lazyLoading('Auth/GotoAuthorize'), },
  { name: 'oauth.callback', path: '/auth/callback', component: lazyLoading('Auth/OauthCallback') },
]
