import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  landings: null,
  queries: {
    per_page: 10,
    page: 1,
    search: null,
    sort_by: 'id',
    sort_desc: false,
  },
  total: 0,
  landingOriginal: {
    title: null,
    description: null,
    keyword: null,
    sections: {
      styling: {
        Fullscreen: false,
        BackgroundImage: "https://images.unsplash.com/photo-1481437156560-3205f6a55735?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxMzY3ODV8MHwxfHNlYXJjaHw5fHxyZXRhaWx8ZW58MHwwfHw&ixlib=rb-1.2.1&q=80&w=1080",
        BackgroundVideo: null,
        BackgroundColor: '#120E0E73',
        Roudness: 4,
        MaxWidth: 600,
      },
      logo: {
        BackgroundColor: "#FFFCFCD1",
        Image: "https://coupon-engine-staging.s3.amazonaws.com/coupons/gWYQXHvs0Bzbd5z7PZTEGRIqWMzu86rIsoH3nhKM.png",
        Link: null,
        MaxWidth: 250,
        Align: 'center',
        Padding: 20,
        Show: true,
      },
      main: {
        BackgroundColor: '#2F2F2FC9',
        Content: 'Join Our VIP Program & Get 10% Off Your Next Order!',
        Color: '#FFFFFF',
        FontSize: 37,
        DescriptionFontSize: 14,
        Align: 'center',
        FontWeight: 'initial',
        FontStyle: 'initial',
        Padding: 27,
        Description: "Enter your information below to download it instantly.",
        Question: 'What is your questions?',
        QuestionDescription: 'Here goes description for question above',
        HeadingColor: '#FFFFFF',
        DescriptionColor: '#FFFFFF',
        DescriptionPadding: 5,
      },
      inputs: {
        BackgroundColor: '#FFFFFF',
        Color: '#414141',
        BorderColor: '#414141',
        Roudness: 5,
        FontSize: 19,
        ShowName: true,
        NameRequired: true,
        ShowEmail: false,
        EmailRequired: true,
        ShowPhone: true,
        PhoneRequired: true,
        ShowBirthday: true,
        BirthdayRequired: true,
        ShowAnniversary: false,
        AnniversaryRequired: true,
        ShowBusinessName: false,
        BusinessNameRequired: true,
        ShowWebsiteUrl: false,
        WebsiteUrlRequired: true,
        BirthdayLabel: 'Join our birthday club for special offers on your birthday',
        AnniversaryLabel: 'Join our anniversary club for special offers on your anniversary',
        NamePlaceholder: 'First Name',
        EmailPlaceholder: 'Enter Your Email',
        PhonePlaceholder: 'Enter Your Cell Phone',
        BusinessNamePlaceholder: 'Business Name',
        WebsiteUrlPlaceholder: 'Website URL',
      },
      actionButton: {
        DisclaimerColor: '#FFFFFF',
        DisclaimerFontSize: 14,
        BackgroundColor: '#2DC056',
        BorderColor: '#2DC056',
        Roudness: 8,
        Label: 'Join Our VIP Program Now!',
        Color: '#FFFFFF',
        FontSize: 28,
        Padding: 10,
        ShowDisclaimerCheckBox: false,
        Disclaimer: 'By submitting this form and signing up for texts, you consent to receive marketing text messages (e.g. promos, cart reminders) from [company name] at the number provided, including messages sent by autodialer. Consent is not a condition of purchase. Msg & data rates may apply. Msg frequency varies. Unsubscribe at any time by replying STOP or clicking the unsubscribe link (where available).',
      },
      legalFooter: {
        Content: 'Copyright © 2022',
        Color: '#FFFF',
        FontSize: 14,
        Align: 'center',
        Link: null,
        LinkColor: '#FFFFFF9e',
        IsPolicyLink: false,
        PolicyLabel: 'Privacy Policy',
        PolicyLink: null,
        IsServiceLink: false,
        ServiceLabel: 'Terms Of Service',
        ServiceLink: null,
      },
      thanks: {
        Content: 'Thank you for submitting your request',
        Padding: 30,
        FontSize: 30,
        BackLabel: 'Back',
        Color: '#FFFFFF',
        BackgroundColor: '#2F2F2FC9'
      },
      scripts: {
        Custom: null,
      }
    },
    loops: {
      styling: {
        Fullscreen: false,
        Roudness: 4,
        MaxWidth: 600,
      },
      main: {
        Title: 'Congrats You Earned [POINTS] Points',
        HeaderLabel1: 'Your Points',
        HeaderLabel2: 'Your Referrals',
        HeaderLabel3: 'Your Ranking',
        HeaderLabel4: 'Rewards Unlocked',
        Content: 'Check Your Phone For Your Coupon Code',
        ContentColor: '#494947',
        ContentBackground: '#fff2cc',
        Description: 'Unlock some cool rewards by completing the actions below!',
        BackgroundColor: '#2F2F2FC9',
        Color: '#FFF',
        Align: 'center',
      },
      points: {
        Title: 'Get points',
        Content: 'Get your friends to sign up with this unique URL',
        BackgroundColor: '#d9d9d9',
        ItemBackground: '#4284f4',
        ItemColor: '#FFFFFF',
      },
      rewards: {
        Title: 'Rewards',
        ItemBackground: '#404040',
        ItemColor: '#FFFFFF',
        IconBackground: '#FFFFFF',
        IconColor: '#c00000',
        UnlockIconBackground: '#FFFFFF',
        UnlockIconColor: '#548235',
        TabBackground: '#548235',
        UnlockTabBackground: '#548235',
        SuccessText: 'Congratulation! You already unlocked this reward.',
        UnlockText: 'Unlock This Reward With [POINTS] Points.'
      }
    },
    votes: {
      result: {
        BackgroundColor: '#2F2F2FC9',
        Color: '#FFFFFF',
        FontSize: 37,
        Align: 'center',
        FontWeight: 'initial',
        FontStyle: 'initial',
        Padding: 27,
        HeadingColor: '#FFFFFF',
        AnswerVoteAlign: 'right',
        AnswerFontSize: 14,
      },
      options: {
        BackgroundColor: '#FFFFFF',
        Color: '#414141',
        BorderColor: '#414141',
        Roudness: 5,
        FontSize: 19,
        AllowMultiple: false,
      },
      actionButton: {
        DisclaimerColor: '#FFFFFF',
        DisclaimerFontSize: 14,
        BackgroundColor: '#2DC056',
        BorderColor: '#2DC056',
        Roudness: 8,
        Label: 'Submit!',
        Color: '#FFFFFF',
        FontSize: 28,
        Padding: 10,
        ShowDisclaimerCheckBox: false,
        Disclaimer: 'Thanks for stopping by and please vote for your favorite item from [company name].',
      },
      share: {
        Show: true,
        ShowTitle: true,
        Title: 'Share Your Result',
        TitleColor: '#FFFFFF',
        TitleFontSize: 15,
        Align: 'center',
        FacebookEnabled: true,
        TwitterEnabled: true,
        LinkedinEnabled: true,
      }
    }
  },
  voteOriginal: {
    title: null,
    description: null,
    keyword: null,
    sections: {
      "logo": {
        "Link": null,
        "Show": true,
        "Align": "center",
        "Image": "https://d3bs3tjqtmdc91.cloudfront.net/coupons/opU6jxUfZWAP5mUFNudlUggL3kV6YXEe7c0HL9Iz.png",
        "Padding": 20,
        "MaxWidth": "250",
        "BackgroundColor": "#FFFCFCD1"
      },
      "main": {
        "Align": "center",
        "Color": "#FFFFFF",
        "Content": "Enter Your Info Below to Verify and Confirm Your Vote",
        "Padding": 27,
        "FontSize": "40",
        "Question": "What Is Your Voting Question?",
        "FontStyle": "initial",
        "FontWeight": "initial",
        "Description": "(Please Note that only verified votes count in the Contest)",
        "HeadingColor": "#FFFFFF",
        "BackgroundColor": "#2F2F2FC9",
        "DescriptionColor": "#FFFFFF",
        "DescriptionPadding": 5,
        "DescriptionFontSize": 14,
        "QuestionDescription": "Cast Your Vote"
      },
      "inputs": {
        "Color": "#414141",
        "FontSize": 19,
        "Roudness": 5,
        "ShowName": true,
        "ShowEmail": true,
        "ShowPhone": true,
        "BorderColor": "#414141",
        "CustomFields": [],
        "NameRequired": true,
        "ShowBirthday": false,
        "BirthdayLabel": "Join our birthday club for special offers on your birthday",
        "EmailRequired": true,
        "PhoneRequired": true,
        "ShowWebsiteUrl": false,
        "BackgroundColor": "#FFFFFF",
        "NamePlaceholder": "First Name",
        "ShowAnniversary": false,
        "AnniversaryLabel": "Join our anniversary club for special offers on your anniversary",
        "BirthdayRequired": true,
        "EmailPlaceholder": "Enter Your Email",
        "PhonePlaceholder": "Enter Your Cell Phone",
        "ShowBusinessName": false,
        "WebsiteUrlRequired": true,
        "AnniversaryRequired": true,
        "BusinessNameRequired": true,
        "WebsiteUrlPlaceholder": "Website URL",
        "BusinessNamePlaceholder": "Business Name"
      },
      "thanks": {
        "Color": "#FFFFFF",
        "Content": "Thank you for submitting your request",
        "Padding": 30,
        "FontSize": 30,
        "BackLabel": "Back",
        "BackgroundColor": "#2F2F2FC9"
      },
      "scripts": {
        "Custom": null
      },
      "styling": {
        "MaxWidth": "800",
        "Roudness": 4,
        "Fullscreen": false,
        "BackgroundColor": "#120E0E73",
        "BackgroundImage": "https://images.unsplash.com/photo-1471922694854-ff1b63b20054?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMzY3ODV8MHwxfHNlYXJjaHwxMHx8b2NlYW58ZW58MHwwfHx8MTcxMTY0NTExN3ww&ixlib=rb-4.0.3&q=80&w=1080",
        "BackgroundVideo": null
      },
      "legalFooter": {
        "Link": null,
        "Align": "center",
        "Color": "#FFFF",
        "Content": "Copyright © 2022",
        "FontSize": 14,
        "LinkColor": "#FFFFFF9e",
        "PolicyLink": null,
        "PolicyLabel": "Privacy Policy",
        "ServiceLink": null,
        "IsPolicyLink": false,
        "ServiceLabel": "Terms Of Service",
        "isPolicyLink": true,
        "IsServiceLink": false,
        "isServiceLink": true
      },
      "actionButton": {
        "Color": "#FFFFFF",
        "Label": "Verify and Confirm My Vote",
        "Padding": 10,
        "FontSize": 28,
        "Roudness": 8,
        "Disclaimer": "By submitting this form and signing up for texts, you consent to receive marketing text messages (e.g. promos, cart reminders) from [company name] at the number provided, including messages sent by autodialer. Consent is not a condition of purchase. Msg & data rates may apply. Msg frequency varies. Unsubscribe at any time by replying STOP or clicking the unsubscribe link (where available).",
        "BorderColor": "#2DC056",
        "BackgroundColor": "#2DC056",
        "DisclaimerColor": "#FFFFFF",
        "DisclaimerFontSize": "10",
        "ShowDisclaimerCheckBox": false
      }
    },
    loops: {
      styling: {
        Fullscreen: false,
        Roudness: 4,
        MaxWidth: 600,
      },
      main: {
        Title: 'Congrats You Earned [POINTS] Points',
        Content: 'Check Your Phone For Your Coupon Code',
        ContentColor: '#494947',
        ContentBackground: '#fff2cc',
        Description: 'Unlock some cool rewards by completing the actions below!',
        BackgroundColor: '#2F2F2FC9',
        Color: '#FFF',
        Align: 'center',
      },
      points: {
        Title: 'Get points',
        Content: 'Get your friends to sign up with this unique URL',
        BackgroundColor: '#d9d9d9',
        ItemBackground: '#4284f4',
        ItemColor: '#FFFFFF',
      },
      rewards: {
        Title: 'Rewards',
        ItemBackground: '#404040',
        ItemColor: '#FFFFFF',
        IconBackground: '#FFFFFF',
        IconColor: '#c00000',
        UnlockIconBackground: '#FFFFFF',
        UnlockIconColor: '#548235',
        TabBackground: '#548235',
        UnlockTabBackground: '#548235',
        SuccessText: 'Congratulation! You already unlocked this reward.'
      }
    },
    votes: {
      "share": {
        "Show": true,
        "Align": "center",
        "Title": "Ask Your Friends To Vote!",
        "ShowTitle": true,
        "TitleColor": "#FFFFFF",
        "TitleFontSize": 15,
        "TwitterEnabled": true,
        "FacebookEnabled": true,
        "LinkedinEnabled": true
      },
      "result": {
        "Align": "center",
        "Color": "#FFFFFF",
        "Padding": 27,
        "FontSize": 37,
        "FontStyle": "initial",
        "FontWeight": "initial",
        "HeadingColor": "#FFFFFF",
        "AnswerFontSize": 14,
        "AnswerVoteAlign": "right",
        "BackgroundColor": "#2F2F2FC9"
      },
      "options": {
        "Color": "#414141",
        "FontSize": 19,
        "Roudness": 5,
        "BorderColor": "#414141",
        "AllowMultiple": false,
        "BackgroundColor": "#FFFFFF"
      },
      "actionButton": {
        "Color": "#FFFFFF",
        "Label": "Submit!",
        "Padding": 10,
        "FontSize": 28,
        "Roudness": 8,
        "Disclaimer": "Thanks for stopping by and please vote for your favorite item from [company name].",
        "BorderColor": "#2DC056",
        "BackgroundColor": "#2DC056",
        "DisclaimerColor": "#FFFFFF",
        "DisclaimerFontSize": 14,
        "ShowDisclaimerCheckBox": false
      },
      "scripts": {
        "Custom": null
      },
    }
  },
  discountTypes: [
    'Percentage Discount',
    'Money Discount',
    'Buy One Get One Free',
    'Free Offer',
    'No Offer - Info Only',
  ],
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { landings, queries, total }) {
      state.landings = landings
      state.queries = queries
      state.total = total

    },

    createSuccess(state, campaign) {
      if (state.landings) {
        state.landings = [...state.landings, campaign]
      }
    },

    updateSuccess(state, campaign) {
      if (state.landings) {
        state.landings = state.landings.map((c) =>
          c.id === campaign.id ? campaign : c
        )
      }
    },

    removeSuccess(state, campaignId) {
      if (state.landings)
      {
        let agencyIndex = state.landings.findIndex((c) => c.id === campaignId)
        state.landings.splice(agencyIndex, 1)
      }
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/landings', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              landings: res.data.data,
              queries: queries,
              total: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    find({ commit }, landingId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/landings/${landingId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    findByTitle({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
        .get(`/pages/${params.id}?business_id=${params.business_id}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    findByToken({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios
        .get(`/loop/${token}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    findRewardByToken({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios
        .get(`/thanks/${token}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    verifyEmailToken({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios
        .get(`/contact/verify/email/${token}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },



    create({ commit }, landing) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/landings`, landing)
          .then((res) => {
            commit('createSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    update({ commit }, landing) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/landings/${landing.id}`, landing)
          .then((res) => {
            commit('updateSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    remove({ commit }, landingId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/landings/${landingId}`)
          .then((res) => {
            commit('removeSuccess', landingId)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    submitVote({ commit }, { type, id, contact }) {
      return new Promise((resolve, reject) => {
        let url = `/landings/${id}/send-contact`
        if (type === 'loop')
          url = `/loops/${id}/send-contact`
        axios
          .post(url, contact)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    sendContact({ commit }, { type, id, contact }) {
      return new Promise((resolve, reject) => {
        let url = `/landings/${id}/send-contact`
        if (type === 'loop')
          url = `/loops/${id}/send-contact`
        axios
          .post(url, contact)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    rewardAction({ commit }, { token, action }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/rewards/${token}/action`, { action: action })
          .then((res) => {
            resolve(res.data.data.reward)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    rewardStats({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/rewards/${token}/stats`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    createReward({ commit }, reward) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/landings/${reward.coupon_id}/rewards`, reward)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateReward({ commit }, reward) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/landings/${reward.coupon_id}/rewards/${reward.id}`, reward)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    changeRewardStatus({ commit }, reward) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/landings/${reward.coupon_id}/rewards/${reward.id}/status`, {})
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteReward({ commit }, reward) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/landings/${reward.coupon_id}/rewards/${reward.id}`, reward)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    receiveReward({ commit }, { token, rewardId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/thanks/${token}/rewards/${rewardId}`, {})
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    orderPoints({ commit }, { landingId, orders }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/landings/${landingId}/order-points`, { orders: orders })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    all(state) {
      return state.landings
    },

    total(state) {
      return state.total
    },

    queries(state) {
      return state.queries
    },

    landingOriginal(state) {
      return state.landingOriginal
    },
    
    voteOriginal(state) {
      return state.voteOriginal
    },

    discountTypes(state) {
      return state.discountTypes
    },
  },
}
