import Vue from 'vue'
import axios from 'axios'
const merge = require('deepmerge')

const initState = {
  businesses: [],
  total: 0,
  fetching: false,
  didFetch: false,
  queries: {
    per_page: 10,
    page: 1,
    status: 'active',
    search: null,
    sort_by: 'id',
    sort_desc: false,
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    loading(state) {
      state.fetching = true
    },
    getAllSuccess(state, res) {
      state.businesses = res.data.data
      state.total = res.data.meta.total
      state.fetching = false
      state.didFetch = true
    },

    updateState(state, obj) {
      Object.keys(obj).forEach(key => {
        state[key] = merge.all([{}, state[key], obj[key]])
      });
    },

    createSuccess(state, { business }) {
      state.businesses = []
    },

    updateSuccess(state, { id, business }) {
      state.businesses = []
    },

    duplicateSuccess(state) {
      state.businesses = []
    },

    archiveSuccess(state) {
      state.businesses = []
    },

    restoreSuccess(state) {
      state.businesses = []
    },

    editSettingsSuccess(state, user) {
      this.state.auth.user = user
    },

    updateBroadcastersSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    assignNumberSuccess(state, phoneNumber) {
      this.state.auth.user.business.phone_number = phoneNumber
    },

    toggleActiveSuccess(state, business) {
      state.businesses = state.businesses.map(b => b.id === business.id ? business : b)
    },
  },

  actions: {
    getAll({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios
          .get('/businesses', { params: state.queries })
          .then((res) => {
            commit('getAllSuccess', res)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    sendTestEmailAction({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios
          .post('/businesses/send-test-email', { email: params.email, block: params.block, business: params.business.id })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    find({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/${businessId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    findByUniqueId({ commit }, uniqueId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/${uniqueId}/unique-id`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    duplicate({ commit }, uniqueId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/${uniqueId}/duplicate`)
          .then((res) => {
            commit('duplicateSuccess')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, business) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses`, business)
          .then((res) => {
            commit('createSuccess', { business: res.data.data })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    update({ commit }, business) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/businesses/${business.id}`, business)
          .then((res) => {
            commit('updateSuccess', {
              id: business.id,
              business: res.data.data,
            })
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    archive({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/businesses/${businessId}`)
          .then(() => {
            commit('archiveSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    delete({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/businesses/${businessId}`)
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resetNumber({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/${businessId}/reset-number`)
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    restore({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/${businessId}/restore`)
          .then((res) => {
            commit('restoreSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    searchNumbers({ commit }, searchParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/search-numbers`, searchParams)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    assignNumber({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/assign-number`, params)
          .then((res) => {
            commit('assignNumberSuccess', params.phone_number)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    assignCustomNumber({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/assign-custom-number`, params)
          .then((res) => {
            commit('assignNumberSuccess', params.phone_number)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    editSettings({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/businesses/edit-settings`, params)
          .then((res) => {
            commit('editSettingsSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateContactInfo({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/update-contact-info`, params)
          .then((res) => {
            commit('updateContactInfoSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateBroadcasters({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/update-broadcasters`, params)
          .then((res) => {
            commit('updateBroadcastersSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getLegalPages({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/legal-pages`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateLegalPages({ commit }, pages) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/businesses/legal-pages`, { pages: pages })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/notifications`)
          .then((res) => {
            resolve(res.data.data.notifications)

          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    templates({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/templates`)
          .then((res) => {
            resolve(res.data.data.templates)

          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    uploadImage({ commit }, uploadForm) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/upload-image`, uploadForm, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteImage({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/delete-image`, params)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getBusinessOptions({ commit }) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios
          .get('/businesses', { params: { per_page: 9999 } })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    adminUpdateBilling({ commit }, { businessId, settings }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/businesses/${businessId}/billing-config`, settings)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    toggleActive({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/${businessId}/active`)
          .then((res) => {
            commit('toggleActiveSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getNumberVerification({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/number-verification`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    numberVerify({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/verify-number`, params)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    findBrandByEin({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/a2p/brand-by-ein/${params.ein}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getCampaingList({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/a2p/campaigns-by-brand/${params.brandId}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    
    getA2pRequest({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/a2p/request`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    registerBrand({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/a2p/brand`, params)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    registerCampaign({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/a2p/campaign`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updatePrefix({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/businesses/a2p/prefix`, params)
          .then((res) => {
            this.dispatch('auth/getProfile')
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getInfo({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/${id}/info`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getTotalEmailMonthly({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/total-email-monthly`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    all(state) {
      return state.businesses
    },

    queries(state) {
      return state.queries
    },

    total(state) {
      return state.total
    },

    countriesBw() {
      return [
        { value: 'US', label: 'United States of America' },
        { value: 'CA', label: 'Canada' },
      ]
    },
    statesBw() {
      return [
        { value: 'AL', label: "Alabama (AL)" },
        { value: 'AK', label: "Alaska (AK)" },
        { value: 'AZ', label: "Arizona (AZ)" },
        { value: 'AR', label: "Arkansas (AR)" },
        { value: 'CA', label: "California (CA)" },
        { value: 'CO', label: "Colorado (CO)" },
        { value: 'CT', label: "Connecticut (CT)" },
        { value: 'DE', label: "Delaware (DE)" },
        { value: 'DC', label: "District Of Columbia (DC)" },
        { value: 'FL', label: "Florida (FL)" },
        { value: 'GA', label: "Georgia (GA)" },
        { value: 'HI', label: "Hawaii (HI)" },
        { value: 'ID', label: "Idaho (ID)" },
        { value: 'IL', label: "Illinois (IL)" },
        { value: 'IN', label: "Indiana (IN)" },
        { value: 'IA', label: "Iowa (IA)" },
        { value: 'KS', label: "Kansas (KS)" },
        { value: 'KY', label: "Kentucky (KY)" },
        { value: 'LA', label: "Louisiana (LA)" },
        { value: 'ME', label: "Maine (ME)" },
        { value: 'MD', label: "Maryland (MD)" },
        { value: 'MA', label: "Massachusetts (MA)" },
        { value: 'MI', label: "Michigan (MI)" },
        { value: 'MN', label: "Minnesota (MN)" },
        { value: 'MS', label: "Mississippi (MS)" },
        { value: 'MO', label: "Missouri (MO)" },
        { value: 'MT', label: "Montana (MT)" },
        { value: 'NE', label: "Nebraska (NE)" },
        { value: 'NV', label: "Nevada (NV)" },
        { value: 'NH', label: "New Hampshire (NH)" },
        { value: 'NJ', label: "New Jersey (NJ)" },
        { value: 'NM', label: "New Mexico (NM)" },
        { value: 'NY', label: "New York (NY)" },
        { value: 'NC', label: "North Carolina (NC)" },
        { value: 'ND', label: "North Dakota (ND)" },
        { value: 'OH', label: "Ohio (OH)" },
        { value: 'OK', label: "Oklahoma (OK)" },
        { value: 'OR', label: "Oregon (OR)" },
        { value: 'PA', label: "Pennsylvania (PA)" },
        { value: 'PR', label: "Puerto Rico (PR)" },
        { value: 'RI', label: "Rhode Island (RI)" },
        { value: 'SC', label: "South Carolina (SC)" },
        { value: 'SD', label: "South Dakota (SD)" },
        { value: 'TN', label: "Tennessee (TN)" },
        { value: 'TX', label: "Texas (TX)" },
        { value: 'UT', label: "Utah (UT)" },
        { value: 'VT', label: "Vermont (VT)" },
        { value: 'VA', label: "Virginia (VA)" },
        { value: 'WA', label: "Washington (WA)" },
        { value: 'WV', label: "West Virginia (WV)" },
        { value: 'WI', label: "Wisconsin (WI)" },
        { value: 'WY', label: "Wyoming (WY)" },
      ]
    },
    statesCaBw() {
      return [
        { value: 'AB' , label: 'Alberta (AB)' },
        { value: 'BC' , label: 'British Columbia (BC)' },
        { value: 'MB' , label: 'Manitoba (MB)' },
        { value: 'NB' , label: 'New Brunswick (NB)' },
        { value: 'NL' , label: 'Newfoundland and Labrador (NL)' },
        { value: 'NF' , label: 'Newfoundland and Labrador (NF)' },
        { value: 'NS' , label: 'Nova Scotia (NS)' },
        { value: 'PE' , label: 'Prince Edward Island (PE)' },
        { value: 'QC' , label: 'Quebec (QC)' },
        { value: 'PQ' , label: 'Quebec (PQ)' },
        { value: 'ON' , label: 'Ontario (ON)' },
        { value: 'SK' , label: 'Saskatchewan (SK)' },
        { value: 'NT' , label: 'Northwest Territories (NT)' },
        { value: 'NU' , label: 'Nunavut (NU)' },
        { value: 'VU' , label: 'Nunavut (VU)' },
        { value: 'YT' , label: 'Yukon (YT)' },
      ]
    },
    verticalsBw() {
      return [
        { value: "REAL_ESTATE", label: "Real Estate" },
        { value: "HEALTHCARE", label: "Healthcare" },
        { value: "ENERGY", label: "Energy" },
        { value: "ENTERTAINMENT", label: "Entertainment" },
        { value: "RETAIL", label: "Retail" },
        { value: "AGRICULTURE", label: "Agriculture" },
        { value: "INSURANCE", label: "Insurance" },
        { value: "EDUCATION", label: "Education" },
        { value: "HOSPITALITY", label: "Hospitality" },
        { value: "FINANCIAL", label: "Financial" },
        { value: "GAMBLING", label: "Gambling" },
        { value: "CONSTRUCTION", label: "Construction" },
        { value: "NGO", label: "NGO" },
        { value: "MANUFACTURING", label: "Manufacturing" },
        { value: "GOVERNMENT", label: "Government" },
        { value: "TECHNOLOGY", label: "Technology" },
        { value: "COMMUNICATION", label: "Communication" },
      ]
    }
  },
}
